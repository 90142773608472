<template>
	<v-tooltip v-bind="$attrs" z-index="300">
		<template v-slot:activator="{ on }">
			<v-btn v-if="canRotate" :small="smallBtn" icon :loading="rotationInProgress" v-on="on" @click="rotatePage()">
				<v-icon>{{ rotationIcon }}</v-icon>
			</v-btn>
		</template>
		<span>{{ rotationTooltip }}</span>
	</v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import * as Files from '@/helpers/files'
import SmallButton from "@/mixins/SmallButton";

export default {
	name: 'RotatePDFDocumentPageButton',
	mixins: [
			DocumentVersionsModuleGuard,
			SmallButton,
	],
	props: {
		document: {
			type: Object,
			required: true
		},
		isReadonly: {
			type: Boolean,
			required: false,
			default: true
		},
		isEditable: {
			type: Boolean,
			required: false,
			default: false
		},
		rotateLeft: {
			type: Boolean,
			required: false,
			default: false
		},
		rotateRight: {
			type: Boolean,
			required: false,
			default: false
		},
		page: {
			type: Number,
			required: true
		},
		version: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			rotationInProgress: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		}),
		handledMimetype: function () {
			return [Files.mimetypes.pdf].includes(this.document.type)
		},
		canRotate: function () {
			return (
				!this.isReadonly &&
				this.isEditable &&
				(this.rotateLeft || this.rotateRight) &&
				!this.document.is_readonly &&
				!this.document.deleted_at &&
				this.handledMimetype
			)
		},
		isClockWise: function () {
			let result
			if (this.rotateLeft && !this.rotateRight) {
				result = false
			} else if (!this.rotateLeft && this.rotateRight) {
				result = true
			} else if (this.rotateLeft && this.rotateRight) {
				throw new Error('RotatePDFDocumentPageButtons cannot rotate to left and right at the same time')
			} else if (!this.rotateLeft && !this.rotateRight) {
				throw new Error('RotatePDFDocumentPageButtons must rotate to left or right not neither')
			}
			return result
		},
		rotationIcon: function () {
			let result = ''
			if (this.rotateLeft && !this.rotateRight) {
				result = 'rotate_left'
			} else if (!this.rotateLeft && this.rotateRight) {
				result = 'rotate_right'
			}
			return result
		},
		rotationTooltip: function () {
			let result = ''
			if (this.rotateLeft && !this.rotateRight) {
				result = this.$t('actions.rotate_page_left')
			} else if (!this.rotateLeft && this.rotateRight) {
				result = this.$t('actions.rotate_page_right')
			}
			return result
		}
	},
	methods: {
		rotatePage: function () {
			this.rotationInProgress = true
			const angle = this.isClockWise ? 90 : 270
			const data = {
				angle: angle,
				page_number: this.page
			}
			return this.service
				.updateDocumentVersion(this.vendorId, this.document.id, this.version, null, data)
				.then(() => {
					this.eventBus.emit(this.events.preview.ROTATE_DOCUMENT_PAGE)
				})
				.finally(() => {
					this.rotationInProgress = false
				})
		}
	}
}
</script>
